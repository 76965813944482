<template>
  <div class="setting-wrap pages" v-loading="loading">
    <div class="preview">
      <img :src="navigateImg">
    </div>
    <div class="setting">

      <el-tabs v-model="activeName" @tab-click="handleClick">

        <el-tab-pane label="店铺banner" name="banner">
          <div class="banner-item" v-for="(item,index) in listData"  :key="item.idNew">
            <div class="uploadWrap">
              <div class="uploadRow">
                <p>
                  <small>建议图片大小500k以内， 尺寸 690X300，支持JPG、PNG、JPEG</small>
                </p>
                <div style="display: flex;align-items: flex-end;justify-content: left">
                  <upload-images-one class="left"
                                     :file.sync="item.pic"
                                     width="80px"
                                     :show-hint-text="false"
                                     :files-size="1"></upload-images-one>
                  <div class="margin-left-30"
                       style="height: 82px;display: flex;flex-direction: column-reverse;justify-content: space-between;">
                    <el-switch
                      style="display: block"
                      v-model="item.status"
                      :active-value="1"
                      :inactive-value="2"
                      active-color="#13ce66"
                      active-text="显示"
                      inactive-text="隐藏">
                    </el-switch>
                  </div>
                </div>
              </div>
            </div>
            <div class="select-item">
              <div class="action">
                <el-button v-if="index > 0" type="primary" size="small" icon="el-icon-top" @click="upClick(index)">上移
                </el-button>
                <el-button v-if="index < listData.length -1" type="primary" size="small" icon="el-icon-bottom"
                           @click="downClick(index)">下移
                </el-button>
                <el-button type="danger" size="small" icon="el-icon-delete" @click="deleteClick(index)">删除</el-button>
              </div>
              <el-select size="small" v-model="item.jumpType" placeholder="请选择" style="width: 200px" @change="switchJumpType(item)">
                <el-option
                  v-for="item in navigateJumpTypes"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <div class="target-wrap">
                <el-button v-for="t in navigateJumpTypes"  :key="t.id"  v-if="t.id > 1 && item.jumpType === t.id"
                           @click="jumpByType(item)" size="small">选择{{t.name}}
                </el-button>
                <div>
                  <span>{{item.source}}</span>
                  <div class="flex-item align-items-start">
                    <img v-if="item.sourceJson.img" :src="item.sourceJson.img" class="square-img left">
                    <div class="flex-1 text-line-hide">{{item.sourceJson.name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-button class="border-dashed" icon="el-icon-plus" size="medium" style="width: 100%" @click="newClick">增加
          </el-button>
          <div v-if="listData.length > 0" style="margin-top: 50px;">
            <el-button type="primary" size="medium" @click="batchSave">保存修改</el-button>
          </div>
        </el-tab-pane>

      </el-tabs>

    </div>

    <select-store-goods :visible.sync="showGoodsDialog" @saveObj="saveObj"></select-store-goods>

  </div>
</template>

<script>
import { baseURL, URL, URL_MINOR } from '../../../config'
import { isEmpty, arrayToObject } from '../../../assets/js/utils'
import SelectStoreGoods from '../../../components/supplier/select/selectStoreGoods'
import UploadImagesOne from '../../../components/public/upload/uploadImagesOne'

export default {
  name: 'adminCmsNavigate',
  components: {
    UploadImagesOne,
    SelectStoreGoods
  },
  data () {
    return {
      loading: false,
      activeName: 'banner',
      selectedIndex: 0,
      navigateJumpTypes: [],
      navigateImgMap: {
        banner: '/images/store/banner.jpg',
      },
      showGoodsDialog: false,
      listData: [],
      editItem: {},
      /* 文件上传 */
      uploadImg: '',
      uploadData: {
        uploadUrl: baseURL + URL_MINOR.upload.uploadImage,
        headers: {
          token: this.$store.state.token,
        },
      },
    }
  },
  created () {
    this.initTypeObjs()
  },
  methods: {
    handleClick (tab, event) {

    },
    getNewData () {
      const jumpType = 1
      return {
        id: '',
        pic: '',
        jumpType: jumpType,
        sourceId: '',
        sourceJson: {},
        descJson: {},
        isShow: 1,
      }
    },
    newClick () {
      this.listData.push(this.getNewData())
    },
    clickIndex (index) {
      this.selectedIndex = index
    },
    initTypeObjs () {
      const getNavigateJumpType = () => {
        return this.axios.get(URL.suppSupplierStore.advertJumpType).then(res => {
          if (res.data) {
            this.navigateJumpTypes = res.data
          }
        }).catch(res => {
          console.log(res)
        })
      }
      Promise.all([getNavigateJumpType()]).then(() => {
        this.getListData()
      })
    },
    getListData (type = this.activeName) {
      this.loading = true
      const data = {
        pageNo: 1,
        pageSize: 100,
        type: type,
        isDelete: 1,
      }
      this.axios.get(URL.suppSupplierStore.advertList, { params: data }).then(res => {
        this.listData = res.data
        if (this.listData.length > 0) {
          this.listData.forEach((item) => {
            try {
              if (item.sourceJson) {
                item.sourceJson = JSON.parse(item.sourceJson)
              } else {
                item.sourceJson = {}
              }
            } catch (e) {
              item.sourceJson = {}
            }
            item.originJump = item.jumpType
          })
        }
      }).catch(() => {
        this.listData = []
        // reject(res);
      }).finally(res => {
        this.loading = false
      })
    },
    // 上移
    upClick (index) {
      console.log('upClick', index)
      const newArr = this.swapItems(this.listData, index, index - 1)
      this.listData = newArr
    },
    // 下移
    downClick (index) {
      console.log('downClick', index)
      const newArr = this.swapItems(this.listData, index, index + 1)
      this.listData = newArr
    },
    // 上下移动的核心。splice函数 返回的是被删除 项  并且 会改变原数组
    // arr.splice(index2, 1, arr[index])[0] 这个得到是的 被删除的 项 并且原数组 已经得到替换。所以需要将被删除项 设置为上一项的值
    // 如果解释不是很清楚。自己去控制台 打印 玩玩  （主要是为自己理解做笔记）
    swapItems (arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0]
      return arr
    },
    deleteClick (index) {
      this.$confirm('删除就没有了', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.listData[index].id) {
          this.loading = true
          const data = {
            supplierStoreAdvertId: this.listData[index].id,
          }
          return this.axios.post(URL.suppSupplierStore.advertTagDelete, this.qs.stringify(data)).then(res => {
            if (res.code === 0) {
              this.listData.splice(index, 1)
            }
          }).catch(res => {

          }).finally(() => {
            this.loading = false
          })
        } else {
          this.listData.splice(index, 1)
        }
      })
    },
    checkSource (item) {
      let result = true
      switch (item.jumpType) {
        case 2001:
        case 2002:
          if (isEmpty(item.source)) {
            this.$message.error('请选择' + this.navigateJumpTypeMap[item.jumpType])
            result = false
          }
          break
      }
      return result
    },
    batchSave () {
      const result = this.listData.every((item, index) => {
        if (!this.checkSource(item)) return false
        item.sort = index
        return true
      })
      if (!result) return false
      this.loading = true
      this.axios.post(URL.suppSupplierStore.configAdvert, this.listData, {
        msgSuccess: true
      }).then(() => {
        this.getListData()
      }).catch(res => {
        if (res.code === 0) {
          this.getListData()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    jumpByType (item) {
      const type = item.jumpType
      if (type === 2001) {
        this.showGoodsDialog = true
      }
      this.editItem = item
    },
    switchJumpType (item) {
      if (item.jumpType !== item.originJump) {
        if (isEmpty(item.originSource) && isEmpty(item.originSourceJson)) {
          item.originSource = item.source
          item.originSourceJson = item.sourceJson
        }
        item.source = ''
        item.sourceJson = {}
      } else {
        item.source = item.originSource
        item.sourceJson = item.originSourceJson
      }
    },
    saveObj (newValue) {
      console.log('get sourceObj', newValue)
      const item = this.editItem
      item.source = newValue.id
      item.sourceJson = newValue
    },
  },
  computed: {
    navigateImg: function () {
      return this.navigateImgMap[this.activeName]
    },
    navigateJumpTypeMap () {
      return arrayToObject(this.navigateJumpTypes)
    },
    selectedItem: function () {
      return this.listData[this.selectedIndex] || {}
    },
  },
  watch: {

  }
}

</script>

<style lang="less">

  @import (reference) "../../../assets/css/data";

  * {
    box-sizing: border-box
  }

  body {
    font-size: 14px;
  }

  p {
    margin: 0;
    padding: 0;
  }

  .action {
    div {
      margin: 10px 0;
    }
  }

  .setting-wrap {
    display: flex;
    flex-wrap: nowrap;

    .preview {
      width: 375px;
      margin-top: 20px;
      text-align: center;

      img {
        width: 375px;
        height: auto;
        max-width: 375px;
      }
    }

    .setting {
      margin-left: 50px;
      width: 850px;
    }

    .banner-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 850px;
      padding: 15px;
      margin-bottom: 15px;
      text-align: left;
      border: 1px dashed #dedede;

      .uploadWrap {
        width: 50%;

        .uploadRow {
          margin-bottom: 25px;

          p {
            padding-bottom: 40px;

            small {
              color: #999;
              font-size: 80%;
            }
          }
        }
      }

      .select-item {
        width: 50%;

        .action {
          margin-bottom: 25px;
        }

        .target-wrap {
          margin-top: 20px;
        }
      }

    }

    .icon-box {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 700px;
      margin-top: 10px;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      .icon-list {
        display: inline-block;
        min-width: 96px;
        padding: 10px 15px;
        margin: 5px 15px;
        cursor: pointer;
        border: 1px dashed #dedede;
        text-align: center;

        .icon-upload {

          display: inline-block;
          width: 60px;
          height: 60px;

          .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;

            &:hover {
              border-color: #07A675;
            }
          }

          img {
            display: block;
            width: 58px;
            height: 58px;
          }

          i {
            display: inline-block;
            width: 58px;
            height: 58px;
            line-height: 56px;
            font-size: 20px;
          }
        }
      }

      .active {
        border: 1px dashed #708fff;
      }
    }

    .project-wrap {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 850px;
      padding: 15px;
      margin-bottom: 15px;
      text-align: left;
      flex-direction: row;
      flex-wrap: wrap;

      .project-item {
        width: 400px;
        margin: 5px;
        padding: 10px;
        border: 1px dashed #dedede;

        .action {
          margin-bottom: 25px;
        }

        .target-wrap {
          margin-top: 20px;
        }
      }

    }

    .notes-wrap{
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 850px;
      padding: 15px;
      margin-bottom: 15px;
      text-align: left;
      flex-direction: row;
      flex-wrap: wrap;

      .notes-item{
        width: 800px;
        margin: 5px;
        padding: 10px;
        border: 1px dashed #dedede;

        display: flex;

        .action{
          width: 45%;
        }
        .target-wrap{
          width: 45%;
        }
      }
    }

    .goods-wrap {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      width: 850px;
      padding: 15px;
      margin-bottom: 15px;
      text-align: left;
      flex-direction: row;
      flex-wrap: wrap;

      .goods-item {
        width: 180px;
        margin: 5px;
        padding: 10px;
        border: 1px dashed #dedede;

        .action {
          margin-bottom: 25px;
        }

        .target-wrap {
          margin-top: 20px;
        }
      }

    }

  }

</style>
